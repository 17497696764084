<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <b-img
          class="developer-img"
          :src="pictureUrl"
          thumbnail
          rounded
          fluid
          left
        />
      </b-col>
      <b-col class="col-md-9">
        <p style="font-weight:bold">{{ name }} {{ country }}</p>
        <p class="division">{{ $t('division') }} : {{ division }}</p>
        <p class="joined">{{ $t('joined') }} : {{ joined }}</p>
        <p class="description">
          {{ $t('short description') }} : {{ description }}
        </p>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'DeveloperInfo',
  props: {
    name: {
      type: String,
      default: 'Michelle Lei',
    },
    country: {
      type: String,
      default: '🇭🇰',
    },
    joined: {
      type: String,
      default: '2018 November',
    },
    division: {
      type: String,
      default: 'CPRD',
    },
    pictureUrl: {
      type: String,
      default: 'https://via.placeholder.com/120x120.png',
    },
    description: {
      type: String,
      default: 'Hello World!',
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../assets/sass/components/DeveloperInfo.scss';
</style>

<i18n>
{
  "en": {
    "division": "Division",
    "joined": "Joined",
    "short description": "Short Description"
  },
  "ja": {
    "division": "ディビジョン",
    "joined": "入社",
    "short description": "簡単な自己紹介"
  }
}
</i18n>
