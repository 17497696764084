<template>
  <b-img :src="loadingIcon" :class="className" />
</template>

<script>
import loadingIcon from '@/assets/hennge_logo_black_vertical.svg';

export default {
  props: {
    className: {
      type: String,
      default: 'loading-icon',
    },
  },
  data() {
    return {
      loadingIcon,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '../assets/sass/components/LoadingIcon.scss';
</style>
