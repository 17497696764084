<template>
  <b-button class="indecisive-btn" to="/restaurant/random">
    <span>
      🤯
    </span>
  </b-button>
</template>

<script>
export default {
  name: 'IndecisiveButton',
};
</script>

<style lang="scss" scoped>
@import '../assets/sass/components/IndecisiveButton.scss';
</style>
