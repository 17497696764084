<template>
  <div>
    <b-img :src="createRestaurant" />
    <b-modal
      id="create-restaurant-modal"
      size="xl"
      hide-footer
      @shown="mapShown"
      @hide="mapHide"
    >
      <b-alert :show="alertShown" dismissible fade variant="warning">
        {{ $t(alertMessage) }}
      </b-alert>
      <div class="overlay" :style="{ display: displayOverlay }" />
      <template #modal-title>
        {{ $t('Add a New Restaurant') }}
      </template>
      <h4 class="new-restaurant-instructions">
        {{ $t('Search for a restaurant by name or find it on the map.') }}
      </h4>
      <div class="new-restaurant-details">
        <p v-if="placeInfo === undefined">
          {{ $t('Cannot find details for the place you selected.') }}
        </p>
        <template v-else-if="placeInfo">
          <div>
            <h4 class="new-restaurant-name">{{ placeInfo.name }}</h4>
            <p class="new-restaurant-address">{{ placeInfo.address }}</p>
          </div>
          <b-button
            class="add-restaurant-btn"
            variant="success"
            :disabled="createRestaurantMutationLoading"
            @click="createRestaurantMutation"
          >
            {{ $t('Submit') }}
          </b-button>
        </template>
        <h4 v-else class="new-restaurant-details-placeholder">
          {{ $t('Details') }}
        </h4>
      </div>
      <div class="autocomplete-container">
        <GmapAutocomplete
          ref="googleAutocomplete"
          :component-restrictions="{ country: ['jp'] }"
          :placeholder="$t('Search by restaurant name')"
          :types="['establishment']"
          :options="options"
          class="autocomplete-input"
          :disabled="autocompleteDisabled"
          @place_changed="setPlace"
        />
      </div>
      <GmapMap
        ref="mapRef"
        :key="googleMapKey"
        :center="mapCenter"
        :zoom="15"
        style="width: 100%; height: 50vh;"
      >
        <GmapMarker
          :position="hennge"
          :clickable="true"
          :draggable="false"
          :icon="henngeMarker"
          @click="mapCenter = hennge"
        />
        <GmapMarker
          v-if="placeId"
          :position="placeLatLng"
          :clickable="true"
          :draggable="false"
          @click="mapCenter = placeLatLng"
        />
      </GmapMap>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { gmapApi } from 'vue2-google-maps';
import gql from 'graphql-tag';
import createRestaurant from '../assets/create_restaurant_filled.svg';
import henngeMarker from '@/assets/hennge_logo_black_vertical.png';

export default {
  name: 'CreateRestaurantModal',
  data() {
    return {
      createRestaurant,
      henngeMarker,
      hennge: { lat: 35.655132, lng: 139.694901 },
      placeId: null,
      placeLatLng: null,
      options: {
        bounds: {
          north: 35.675769,
          south: 35.640072,
          east: 139.724637,
          west: 139.667386,
        },
        strictBounds: true,
      },
      mapCenter: { lat: 35.655132, lng: 139.694901 },
      alertShown: false,
      alertMessage: '',
      placeInfo: null,
      createRestaurantMutationLoading: false,
      autocompleteDisabled: false,
      googleMapKey: 'googleMap-0',
      mapClickedListener: null,
      displayOverlay: 'none',
    };
  },
  computed: {
    google: gmapApi,
  },
  methods: {
    ...mapActions(['updateModalVisible']),
    setPlace(place) {
      if (place.place_id) {
        this.googlePlaceQuery(place.place_id);
        this.placeId = place.place_id;
        this.placeLatLng = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };
        this.mapCenter = this.placeLatLng;
      } else {
        this.showAlert('This place is not recognized on Google Maps.');
      }
    },
    showAlert(message) {
      this.alertMessage = message;
      this.alertShown = true;
      setTimeout(() => {
        this.alertShown = false;
      }, 3000);
    },
    createRestaurantMutation() {
      this.disableUserInteractions();
      if (this.placeId) {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation createRestaurant($place_id: ID!) {
                createRestaurant(place_id: $place_id) {
                  new_restaurant {
                    id
                  }
                }
              }
            `,
            variables: {
              place_id: this.placeId,
            },
          })
          .then(({ data }) => {
            this.enableUserInteractions();
            this.$root.$bvModal.hide('create-restaurant-modal');
            this.$router.push(
              `/restaurant/${data.createRestaurant.new_restaurant.id}`
            );
            setTimeout(() => {
              this.$emit('created');
            }, 400);
          })
          .catch(error => {
            this.enableUserInteractions();
            if (
              error.message &&
              error.message.includes('is not a food place')
            ) {
              this.showAlert('The place you selected is not a restaurant.');
            } else {
              this.showAlert('This restaurant is already in Gohunter.');
            }
          });
      }
    },
    googlePlaceQuery(placeId) {
      this.disableUserInteractions();
      this.$apollo
        .query({
          query: gql`
            query googlePlace($placeId: ID!) {
              googlePlace(placeId: $placeId) {
                place_id
                name
                address
              }
            }
          `,
          variables: {
            placeId,
          },
        })
        .then(({ data }) => {
          this.enableUserInteractions();
          this.placeInfo = { ...data.googlePlace };
          this.displayOverlay = 'none';
          this.$refs.googleAutocomplete.$refs.input.value = '';
        })
        .catch(error => {
          this.enableUserInteractions();
          this.placeInfo = undefined;
          this.displayOverlay = 'none';
        });
    },
    mapClicked(event) {
      if (event.placeId) {
        this.googlePlaceQuery(event.placeId);
        this.placeId = event.placeId;
        this.placeLatLng = {
          lat: event.latLng.lat(),
          lng: event.latLng.lng(),
        };
        this.mapCenter = this.placeLatLng;
      }
    },
    mapShown() {
      this.updateModalVisible(true);
      this.placeId = null;
      this.placeLatLng = null;
      this.placeInfo = null;
      this.mapCenter = this.hennge;
      this.googleMapKey =
        this.googleMapKey === 'googleMap-0' ? 'googleMap-1' : 'googleMap-0';
      setTimeout(() => {
        this.$refs.mapRef.$mapPromise.then(map => {
          this.mapClickedListener = google.maps.event.addListener(
            map,
            'click',
            this.mapClicked
          );
        });
      }, 100);
    },
    mapHide() {
      this.updateModalVisible(false);
      google.maps.event.removeListener(this.mapClickedListener);
      this.mapClickedListener = null;
    },
    disableUserInteractions() {
      this.createRestaurantMutationLoading = true;
      this.autocompleteDisabled = true;
      this.displayOverlay = 'initial';
    },
    enableUserInteractions() {
      this.createRestaurantMutationLoading = false;
      this.autocompleteDisabled = false;
      this.displayOverlay = 'none';
    },
  },
};
</script>

<i18n>
{
    "en": {
        "Add a New Restaurant": "Add a New Restaurant",
        "Details":"Details",
        "Submit": "Submit",
        "Cannot find details for the place you selected.": "Cannot find details for the place you selected.",
        "This place is not recognized on Google Maps.":"This place is not recognized on Google Maps.",
        "The place you selected is not a restaurant.": "The place you selected is not a restaurant.",
        "This restaurant is already in Gohunter.": "This restaurant is already in Gohunter.",
        "Details": "Details",
        "Search for a restaurant by name or find it on the map.": "Search for a restaurant by name or find it on the map.",
        "Search by restaurant name": "Search by restaurant name"
    },
    "ja": {
        "Add a New Restaurant": "レストランを追加する",
        "Details":"詳細",
        "Submit": "登録",
        "Cannot find details for the place you selected.": "選択された店の詳細は見つかりません。",
        "This place is not recognized on Google Maps.":"このレストランはGoogle Mapsに登録されていません。",
        "The place you selected is not a restaurant.":"選択された店はレストランではありません。",
        "This restaurant is already in Gohunter.": "このレストランはすでに登録されています。",
        "Search for a restaurant by name or find it on the map.": "名前で検索するか、地図で選択してください。",
        "Search by restaurant name": "名前で検索"
    }
}
</i18n>
