export default [
  {
    name: 'GONPACHI',
    cuisine: 'teishoku',
    thumbnail:
      'https://gonpachi.jp/wp-content/uploads/sites/14/2018/12/gonpachi-soba-kushi02.jpg',
    link: 'https://tabelog.com/en/tokyo/A1303/A130301/13005404/',
    gmap:
      'https://maps.googleapis.com/maps/api/staticmap?client=gme-kakakucominc&channel=tabelog.com&sensor=false&hl=ja&center=35.657396524869895,139.69588348468187&markers=color:red%7C35.657396524869895,139.69588348468187&zoom=15&size=220x180&language=en&signature=GvMF3lDALOqo9mrSEHd7600ld2k=',
    lunches: ['FID0', 'FID1', 'FID2'],
    likes: 0,
  },
];
