<template>
  <div style="position: absolute; right: 0;">
    <div class="filter-btn" @click="$bvModal.show('restaurant-filters')">
      <font-awesome-icon icon="filter" size="lg" />
      {{ $t('filters') }}
    </div>

    <b-modal
      id="restaurant-filters"
      modal-class="RestaurantFilter"
      :ok-title="$t('apply')"
      ok-variant="success"
      :cancel-title="$t('cancel')"
      :hide-footer="loading"
      @show="onShow"
      @hide="updateModalVisible(false)"
      @ok="onOKClicked()"
    >
      <template #modal-title>
        {{ $t('filters') }}
      </template>
      <div class="d-block text-center">
        <LoadingIcon v-if="loading" />
        <b-form-group v-else>
          <b-button
            class="clear-btn"
            pill
            size="sm"
            variant="danger"
            @click="clearAll"
          >
            {{ $t('clear') }}
          </b-button>
          <b-form-checkbox-group
            id="tags-checkbox-group"
            v-model="selected"
            class="tags-checkbox-group"
            :options="allTags"
            value-field="id"
            text-field="name"
            name="filters"
            buttons
            button-variant="outline-success"
          />
        </b-form-group>
      </div>
    </b-modal>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { mapActions, mapState } from 'vuex';
import LoadingIcon from '@/components/LoadingIcon.vue';

export default {
  name: 'FilterModal',
  components: {
    LoadingIcon,
  },
  data() {
    return {
      allTags: [],
      selected: this.tagFilters, // Must be an array reference!
    };
  },
  apollo: {
    allTags: {
      query: gql`
        query allTags {
          allTags: tags {
            id
            name
          }
        }
      `,
      update(data) {
        if (!data.allTags) {
          return data.allTags;
        }
        return data.allTags.sort(this.compare);
      },
    },
  },
  computed: {
    ...mapState(['tagFilters']),
    loading() {
      return (
        this.$apolloData.queries.allTags.loading ||
        !this.allTags ||
        this.allTags.length == 0
      );
    },
  },
  methods: {
    ...mapActions(['updateModalVisible']),
    onShow() {
      this.updateModalVisible(true);
      this.selected = this.tagFilters;
    },
    clearAll() {
      this.selected = [];
    },
    tagIdsToTagNames(tagIds) {
      return this.allTags
        .filter(tag => tagIds.includes(tag.id))
        .map(tag => tag.name);
    },
    directHomeWithFilters() {
      if (this.selected && this.selected.length > 0) {
        const selectedTagNames = this.tagIdsToTagNames(this.selected);
        const queryParams = selectedTagNames.join().toLowerCase();
        this.$router.replace({ query: { filters: queryParams } });
      } else {
        this.$router.replace({ query: {} });
      }
    },
    onOKClicked() {
      this.$emit('okClicked');
      this.directHomeWithFilters();
    },
    compare(tagA, tagB) {
      // Use toUpperCase() to ignore character casing
      const nameA = tagA.name.toUpperCase();
      const nameB = tagB.name.toUpperCase();

      let comparison = 0;
      if (nameA > nameB) {
        comparison = 1;
      } else if (nameA < nameB) {
        comparison = -1;
      }
      return comparison;
    },
  },
};
</script>

<i18n>
{
  "en": {
    "filters": "Filters",
    "apply": "Apply",
    "cancel": "Cancel",
    "clear": "Clear"
  },
  "ja": {
    "filters": "フィルター",
    "apply": "適応",
    "cancel": "キャンセル",
    "clear": "クリア"
  }
}
</i18n>
