<template>
  <div>
    <LunchReport
      v-for="lunch in lunchesSortedByDateDes"
      :key="`lunch-${lunch.id}`"
      :lunch="lunch"
      :display-restaurant-name="displayRestaurantName"
    />
  </div>
</template>

<script>
import LunchReport from '@/components/LunchReport.vue';

export default {
  name: 'LunchReports',
  components: {
    LunchReport,
  },
  props: {
    lunches: {
      type: Array,
      default: () => [],
    },
    displayRestaurantName: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    lunchesSortedByDateDes() {
      return [...this.lunches].sort(this.compare);
    },
  },
  methods: {
    compare(lunchA, lunchB) {
      const dateA = new Date(lunchA.lunch_date);
      const dateB = new Date(lunchB.lunch_date);

      // if either of the lunch date is undefined, can't do anything, send them to bottom
      if (!dateA || !dateB) {
        return 0;
      }

      let comparison = 0;
      if (dateA < dateB) {
        comparison = 1;
      } else if (dateA > dateB) {
        comparison = -1;
      }
      return comparison;
    },
  },
};
</script>
