<template>
  <div>
    <div>
      <div v-if="innerWidth >= 768" class="btn-indecisive-outer">
        <b-button
          to="/restaurant/random"
          :class="
            scrollTop
              ? 'btn-indecisive-inner-scrolltop'
              : 'btn-indecisive-inner-scrolled'
          "
        >
          {{ $t("I'm Feeling Indecisive") }}
        </b-button>
      </div>
      <IndecisiveButton v-else />
      <div id="home-tool-bar">
        <b-input-group id="search-bar">
          <b-form-input
            v-model="searchString"
            type="text"
            :placeholder="$t('Search restaurants')"
            @input="debounceSearchInput"
          />
        </b-input-group>
        <FilterModal @okClicked="searchString = ''" />
      </div>
      <div v-if="operatingRestaurants.length == 0">
        <div v-if="loading">
          <b-row v-for="i in 12 / item_per_row" :key="`row-${i}`">
            <b-card-group>
              <DummyPreview
                v-for="j in item_per_row"
                :key="`row-${i}-col-${j}`"
                :image-height="imageHeight"
              />
            </b-card-group>
          </b-row>
        </div>
        <p v-else style="margin-top: 2%; font-size: 1.5rem;">
          {{ $t('Oops... No results found.') }}
        </p>
      </div>
      <div v-else>
        <b-row
          v-for="i in Math.ceil(operatingRestaurants.length / item_per_row)"
          :key="i"
        >
          <b-card-group>
            <RestaurantPreview
              v-for="restaurant in operatingRestaurants.slice(
                (i - 1) * item_per_row,
                i * item_per_row
              )"
              :id="restaurant.id"
              :key="restaurant.id"
              :restaurant="restaurant"
              :image-height="imageHeight"
            />
          </b-card-group>
        </b-row>
      </div>
    </div>
    <b-row v-if="loading">
      <b-col>
        <LoadingIcon />
        <b-col />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import gql from 'graphql-tag';
import debounce from 'lodash/debounce';
import RestaurantPreview from '@/components/RestaurantPreview.vue';
import DummyPreview from '@/components/DummyPreview.vue';
import LoadingIcon from '@/components/LoadingIcon.vue';
import IndecisiveButton from '@/components/IndecisiveButton.vue';
import FilterModal from '@/components/FilterModal.vue';
import Restaurants from '@/graphql/Restaurants.gql';
import SearchRestaurants from '@/graphql/SearchRestaurants.gql';

const pageSize = 21;
const searchInputDebouncePeriod = 300; // in ms

export default {
  name: 'Home',
  components: {
    RestaurantPreview,
    DummyPreview,
    LoadingIcon,
    IndecisiveButton,
    FilterModal,
  },
  data() {
    return {
      item_per_row: window.innerWidth >= 768 ? 3 : 1,
      restaurantOffset: 0,
      imageHeight: Math.floor(window.innerHeight * 0.2),
      restaurants: [],
      hasMore: true,
      innerWidth: window.innerWidth,
      scrollTop: true,
      searchString: '',
    };
  },
  apollo: {
    // Pages
    restaurants: {
      // GraphQL Query
      query: Restaurants,
      // Initial variables
      variables() {
        return {
          count: pageSize,
          offset: 0,
          withTags: this.tagFilters,
        };
      },
      fetchPolicy: 'network-only',
    },
    allTags: gql`
      {
        allTags: tags {
          id
          name
        }
      }
    `,
    searchRestaurants: {
      query: SearchRestaurants,
      variables() {
        return {
          name: this.searchString,
        };
      },
    },
  },
  computed: {
    ...mapState(['selected', 'tagFilters']),
    locale() {
      return this.$root.$i18n.locale;
    },
    loading() {
      return this.searchString
        ? this.$apollo.queries.searchRestaurants.loading
        : (this.$apollo.queries.restaurants.loading || !this.restaurants) &&
            this.hasMore;
    },
    operatingRestaurants() {
      // show restaurants from the search result if the search box is non-empty
      if (this.searchString) {
        return this.searchRestaurants;
      }
      /* When users don't specify any filters, the backend returns everything,
         including permanently closed restaurants. However, it makes sense to exclude them
         unless user specifically has it in their filters. */
      if (this.tagFilters == false && this.restaurants) {
        const isOperating = restaurant =>
          !restaurant.tags.map(tag => tag.name).includes('permanently closed');
        return this.restaurants.filter(isOperating);
      }
      return this.restaurants ? this.restaurants : [];
    },
    queryParams() {
      return this.$route.query;
    },
    allTagsLowerToIdMap() {
      let map;
      if (this.allTags) {
        map = Object.fromEntries(
          this.allTags.map(t => [t.name.toLowerCase(), t.id])
        );
      }
      return map;
    },
  },
  watch: {
    locale() {
      this.refreshRestaurants();
    },
    restaurants(newRestaurants) {
      if (newRestaurants) {
        this.restaurantOffset = Math.max(
          this.restaurantOffset,
          newRestaurants.length - pageSize
        );
      }
    },
    tagFilters() {
      this.refreshRestaurants();
    },
    queryParams() {
      this.updateFiltersByQueryParams();
    },
    allTagsLowerToIdMap(newMap) {
      if (newMap) {
        const params = this.$route.query;
        if (
          (!params || !params.filters) &&
          this.tagFilters &&
          this.tagFilters.length > 0
        ) {
          this.resetURLQueryParams();
        } else {
          this.updateFiltersByQueryParams();
        }
      }
    },
  },
  created() {
    window.addEventListener('resize', this.onResize);
    this.handleDebouncedScroll = debounce(this.handleScroll, 100);
    window.addEventListener('scroll', this.handleDebouncedScroll);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
    window.removeEventListener('scroll', this.handleDebouncedScroll);
  },
  methods: {
    ...mapActions(['updateSelectedCard', 'updateTagFilters']),
    hoverCard(selectedIndex) {
      this.updateSelectedCard(selectedIndex);
    },
    isSelected(cardIndex) {
      return this.selected === cardIndex;
    },
    onResize() {
      // bootstrap vue layout system, the definition of md is 768px
      if (window.innerWidth >= 768) {
        this.item_per_row = 3;
      } else {
        this.item_per_row = 1;
      }
      this.imageHeight = Math.floor(window.innerHeight * 0.2);
      this.innerWidth = window.innerWidth;
    },
    handleScroll() {
      const bottomOfWindow =
        document.documentElement.scrollTop + window.innerHeight ===
        document.documentElement.offsetHeight;
      this.scrollTop = document.documentElement.scrollTop < 50;
      if (bottomOfWindow) {
        this.restaurantOffset += pageSize;
        this.$apollo.queries.restaurants.fetchMore({
          // New variables
          variables: {
            count: pageSize,
            offset: this.restaurantOffset,
          },
          // Transform the previous result with new data
          updateQuery: (previousResult, { fetchMoreResult }) => {
            if (fetchMoreResult.restaurants.length === 0) {
              this.hasMore = false;
              return previousResult;
            }
            this.hasMore = true;
            const newRestaurants = fetchMoreResult.restaurants;
            return {
              restaurants: [...previousResult.restaurants, ...newRestaurants],
            };
          },
        });
      }
    },
    debounceSearchInput: debounce(function handleSearchInput(newValue) {
      this.searchString = newValue;
    }, searchInputDebouncePeriod),
    refreshRestaurants() {
      this.restaurants = [];
      window.scrollTo(0, 0);
      this.restaurantOffset = 0;
      this.$apollo.queries.restaurants.refresh();
    },
    tagIdsToTagNames(tagIds) {
      return this.allTags
        .filter(tag => tagIds.includes(tag.id))
        .map(tag => tag.name);
    },
    resetURLQueryParams() {
      // fallback to previous state of url query params
      const tagFilterNames = this.tagIdsToTagNames(this.tagFilters);
      const queryParams = tagFilterNames.join().toLowerCase();
      // the next line will invoke a change in queryParams
      this.$router.replace({ query: { filters: queryParams } });
    },
    updateFiltersByQueryParams() {
      const params = this.$route.query;
      if (params && params.filters) {
        const filters = params.filters.toLowerCase().split(',');
        const validFilterIds = [];
        filters.forEach(tagLower => {
          if (this.allTagsLowerToIdMap[tagLower]) {
            validFilterIds.push(this.allTagsLowerToIdMap[tagLower]);
          }
        });
        // if all tag names in filters are valid
        if (validFilterIds.length === filters.length) {
          // set filters
          this.updateTagFilters(validFilterIds);
        } else if (this.tagFilters.length > 0) {
          this.resetURLQueryParams();
        } else {
          // fallback to previous state of url query params
          this.$router.replace({ query: {} });
        }
      } else {
        this.updateTagFilters([]);
      }
    },
  },
};
</script>

<i18n>
{
  "en": {
    "I'm Feeling Indecisive": "I'm Feeling Indecisive",
    "Search restaurants": "Search restaurants",
    "Oops... No results found.": "Oops... No results found."
  },
  "ja": {
    "I'm Feeling Indecisive": "決められない！",
    "Search restaurants": "店を検索",
    "Oops... No results found.": "あっ！結果が見つかりません。"
  }
}
</i18n>
