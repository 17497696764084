<template>
  <div
    class="mobile-nav-container"
    :class="{ 'mobile-nav-container--right': navDrawerShown }"
  >
    <div
      id="new-feat-popover"
      class="site-icon-wrapper"
      @click="$emit('click')"
    >
      <font-awesome-icon icon="bars" size="2x" />
    </div>
    <b-img :src="Logo" class="site-icon" />
    <b-popover
      target="new-feat-popover"
      placement="bottom"
      :show="!seenNewFeat"
      :disabled="seenNewFeat"
      triggers="click blur"
      >{{ $t("Now you can read reports from lunches you've had!") }}</b-popover
    >
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Logo from '@/assets/filled-riceball.svg';
import jaLogo from '@/assets/ja-logo.svg';
import enLogo from '@/assets/en-logo.svg';

export default {
  name: 'MobileMenuButton',
  props: {
    navDrawerShown: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      Logo,
      jaLogo,
      enLogo,
    };
  },
  computed: {
    ...mapState(['seenNewFeat']),
  },
};
</script>

<style lang="scss" scoped>
@import '../assets/sass/base/variables';

.mobile-nav-container {
  display: flex;
  justify-content: space-between;
  padding-right: 12px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1020;
  background-color: $primary-color;
  border-radius: 8px;
  transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1); // match the transition of the drawer
  &--right {
    transform: translateX(256px); //match the width of the drawer
  }
}

.site-icon-wrapper {
  background-color: rgba(grey, 0.1);
  &:active {
    background-color: rgba(lightgrey, 0.2);
  }

  padding: 12px;
}

.site-icon {
  height: 50px;
}
</style>

<i18n>
{
  "en": {
    "Now you can read reports from lunches you've had!": "Now you can read reports from lunches you've had!"
  },
  "ja": {
    "Now you can read reports from lunches you've had!": "参加したGIPランチのレポートを確認できるようになりました！"
  }
}
</i18n>
