<template>
  <v-navigation-drawer
    :value="drawer"
    :color="color"
    fixed
    temporary
    :stateless="modalVisible"
    class="navDrawer"
    disable-resize-watcher
    @input="onInputChange"
  >
    <v-list dense nav class="py-0">
      <v-list-item two-line>
        <v-list-item-content>
          <b-img :src="Logo" class="brand-name-icon" />
          <b-img
            :src="locale == 'ja' ? jaLogoName : enLogoName"
            class="brand-name-icon"
          />
        </v-list-item-content>
      </v-list-item>

      <v-divider />

      <v-list-item to="/">
        <v-list-item-icon>
          <font-awesome-icon icon="home" size="lg" />
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ $t('Home') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/about">
        <v-list-item-icon>
          <font-awesome-icon icon="question-circle" size="lg" />
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ $t('About') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="me && me.inGIPLunch" to="/profile/my-lunches">
        <v-list-item-icon>
          <font-awesome-icon icon="utensils" size="lg" />
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ $t('My Lunches') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-group class="list-group--language">
        <template #activator>
          <v-list-item-icon>
            <font-awesome-icon icon="globe" size="lg" />
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="list-item__title--language">{{
              $t('Language')
            }}</v-list-item-title>
          </v-list-item-content>
        </template>
        <v-list-item
          v-for="language in languages"
          :key="language.key"
          @click="onLanguageChange(language.key)"
        >
          <v-list-item-content>
            <v-list-item-title v-text="language.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </v-list>
    <v-divider />
    <MoreActions />
    <template #append>
      <v-btn
        block
        height="50"
        class="logout-btn"
        @click="$refs.googleLogout.$el.click()"
      >
        <v-avatar size="40" left>
          <img
            :src="me ? me.employee.slack_user.avatar : blankAvatar"
            alt="user_profile "
          />
        </v-avatar>
        {{ $t('logout') }}
        <GoogleLogin
          ref="googleLogout"
          :params="params"
          :logout-button="true"
          :on-success="onSignOut"
          :on-failure="onSignOut"
        >
        </GoogleLogin>
      </v-btn>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from 'vuex';
import GoogleLogin from 'vue-google-login';
import { createApolloClient } from 'vue-cli-plugin-apollo/graphql-client';
import debounce from 'lodash/debounce';
import { AUTH_TOKEN, httpEndpoint } from '../vue-apollo';
import MoreActions from '@/components/MoreActions.vue';
import Logo from '@/assets/filled-riceball.svg';
import jaLogo from '@/assets/ja-logo.svg';
import enLogo from '@/assets/en-logo.svg';
import jaLogoName from '@/assets/ja-logo-name.svg';
import enLogoName from '@/assets/en-logo-name.svg';
import blankAvatar from '@/assets/blank_avatar.jpg';
import Me from '@/graphql/Me.gql';

export default {
  name: 'NavDrawer',
  components: {
    MoreActions,
    GoogleLogin,
  },
  props: {
    drawer: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      blankAvatar,
      color: 'primary',
      colors: ['primary', 'blue', 'success', 'red', 'teal'],
      background: false,
      Logo,
      jaLogo,
      enLogo,
      jaLogoName,
      enLogoName,
      languages: [
        { key: 'ja', title: '日本語' },
        { key: 'en', title: 'English' },
      ],
      params: {
        client_id:
          '267943453777-99qfoi92aauf3iidfiq0ir209juumdoc.apps.googleusercontent.com',
        fetch_basic_profile: false,
        scope: 'email profile openid',
        hosted_domain: 'hennge.com',
      },
    };
  },
  apollo: {
    // Pages
    me: {
      // GraphQL Query
      query: Me,
    },
  },
  computed: {
    ...mapState(['modalVisible']),
    bg() {
      return this.background
        ? 'https://cdn.vuetifyjs.com/images/backgrounds/bg-2.jpg'
        : undefined;
    },
    locale() {
      return this.$i18n.locale;
    },
  },
  methods: {
    onInputChange(input) {
      if (!input && this.drawer) {
        this.$emit('close');
      }
    },
    async onLanguageChange(toLanguage) {
      this.$root.$i18n.locale = toLanguage;
      localStorage.setItem('Preferred_locale', toLanguage);
      const { apolloClient, wsClient } = createApolloClient({
        httpEndpoint,
        wsEndpoint: null,
        tokenName: AUTH_TOKEN,
        persisting: false,
        websocketsOnly: false,
        ssr: false,
        httpLinkOptions: {
          headers: {
            'Accept-Language': toLanguage,
          },
        },
      });
      apolloClient.wsClient = wsClient;
      this.$apollo.provider.defaultClient = apolloClient;
    },
    onSignOut() {
      // do nothing
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../assets/sass/components/NavDrawer.scss';
</style>

<i18n>
{
  "en": {
    "Home": "Home",
    "Language": "Language",
    "About": "About",
    "logout": "Logout",
    "Now you can read reports from lunches you've had!": "Now you can read reports from lunches you've had!",
    "My Lunches": "My Lunches"
  },
  "ja": {
    "Home": "ホーム",
    "Language": "言語",
    "About": "概要",
    "logout": "ログアウト",
    "Now you can read reports from lunches you've had!": "参加したGIPランチのレポートを確認できるようになりました！",
    "My Lunches": "マイランチ"
  }
}
</i18n>
