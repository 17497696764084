<template>
  <div>
    <div v-if="restaurant" id="restaurant-detail" class="RestaurantDetail">
      <b-row class="RestaurantDetail__title">
        <b-link @click="$router.go(-1)">
          <font-awesome-icon icon="chevron-left" size="lg" />
        </b-link>
        <h1 class="RestaurantDetail__title_text">{{ restaurant.name }}</h1>
        <Rating
          v-if="restaurant.rating && !permanentlyClosed"
          :rating="restaurant.rating"
        />
      </b-row>
      <b-img
        v-if="permanentlyClosed"
        :src="closedSign"
        class="container-centered"
      />
      <div v-else>
        <Carousel3d
          v-if="restaurant.photos.length > 0"
          id="restaurant-carousel"
          class="RestaurantCarousel"
          autoplay
          :autoplay-timeout="autoplayTimeout"
          autoplay-hover-pause
          :controls-visible="controlsVisible"
        >
          <Slide
            v-for="(photo, index) in restaurant.photos"
            :key="photo"
            :index="index"
          >
            <b-img :src="photo" class="RestaurantCarousel__image" />
          </Slide>
        </Carousel3d>
        <div class="RestaurantDetailBody">
          <iframe
            v-if="restaurant.place_id"
            class="RestaurantDetailBody__google-maps"
            frameborder="0"
            style="border: 0;"
            :src="
              `https://www.google.com/maps/embed/v1/place?q=place_id:${restaurant.place_id}&key=${google_maps_api_key}&language=${$i18n.locale}`
            "
            allowfullscreen
          />
          <div class="RestaurantDetails">
            <div v-if="restaurant.website" class="RestaurantDetails__row">
              <font-awesome-icon icon="globe-asia" size="lg" />
              <p>
                <a :href="restaurant.website" target="_blank">
                  {{
                    restaurant.website
                      .replace(/^(http:\/\/)/, '')
                      .replace(/^(https:\/\/)/, '')
                  }}
                </a>
              </p>
            </div>
            <div v-if="restaurant.phone_number" class="RestaurantDetails__row">
              <font-awesome-icon icon="phone-alt" size="lg" />
              <p>
                <a :href="`tel:${restaurant.phone_number}`">
                  {{ restaurant.phone_number }}</a
                >
              </p>
            </div>
            <div class="RestaurantDetails__row">
              <font-awesome-icon icon="map-marker-alt" size="lg" />
              <p>{{ restaurant.address }}</p>
            </div>
            <div
              v-if="restaurant.lunch_count > 0"
              class="RestaurantDetails__row"
            >
              <font-awesome-icon icon="utensils" size="lg" />
              <p>
                {{ restaurant.lunch_count }}
                {{ $tc('GIP Lunch', restaurant.lunch_count) }}
              </p>
            </div>
            <!-- TAGS HANDLING SYSTEM -->
            <div class="RestaurantDetailTags">
              <div class="RestaurantDetailTags__tag-container">
                <font-awesome-icon icon="tags" size="xs" />
                <b-spinner v-if="loading" small label="Small Spinner" />
                <div v-else class="RestaurantDetailTags__tags">
                  <!-- NO TAGS -->
                  <span
                    v-if="
                      (isEditingTags && tempEditedTags.length < 1) ||
                        (!isEditingTags && tags.length < 1)
                    "
                    class="no-tag-placeholder"
                  >
                    {{ $t('No tags') }}
                  </span>
                  <!-- DISPLAYING TAGS -->
                  <template v-if="!isEditingTags">
                    <b-link
                      v-for="tag in tags"
                      :key="tag.id"
                      class="RestaurantDetailTags__tag"
                      @click="redirectHomeWithTagFilter(tag)"
                    >
                      #{{ tag.name }}&nbsp;
                    </b-link>
                    <font-awesome-icon
                      icon="cog"
                      size="xs"
                      @click="isEditingTagsOn"
                    />
                  </template>
                  <!-- EDITING TAGS -->
                  <template v-else>
                    <b-button-group
                      v-for="tag in tempEditedTags"
                      :key="tag.id"
                      size="sm"
                      class="tag-btn-group"
                    >
                      <b-button disabled variant="outline-info"
                        >#{{ tag.name }}</b-button
                      >
                      <b-button variant="info" @click="unTag(tag.id)">
                        ✖️
                      </b-button>
                    </b-button-group>
                    <b-button
                      pill
                      size="sm"
                      class="save-btn"
                      @click="isEditingTagsOff"
                    >
                      {{ $t('Save') }}
                    </b-button>
                  </template>
                </div>
              </div>
              <vue-select
                v-if="addableTags.length > 0"
                ref="tagDropdown"
                v-model="selectedTag"
                class="RestaurantDetailTags__tag-select"
                :style="{ visibility: isEditingTags ? 'visible' : 'hidden' }"
                label="name"
                :options="addableTags"
                :placeholder="$t('Add a new tag')"
                :reset-on-options-change="true"
                :select-on-tab="true"
                @input="addTag"
              />
            </div>
            <!-- / TAGS HANDLING SYSTEM -->
          </div>
        </div>
        <div v-if="me.inGIPLunch">
          <h1 class="RestaurantDetail__title_text">{{ $t('Lunches') }}</h1>
          <b-button
            class="open-submit-report-btn"
            @click="openSubmitReportBtnClicked"
          >
            {{ $t('Report') }}
          </b-button>
          <LunchReports
            v-if="restaurant.lunches.length"
            :lunches="restaurant.lunches"
            :display-restaurant-name="false"
          />
          <p v-else class="no-tag-placeholder">
            {{ $t('There have been no GIP lunches at this restaurant') }}
          </p>
        </div>
      </div>
    </div>
    <!-- IF NO RESTAURANT -->
    <div v-else>
      <b-row>
        <h1>
          <b-link to="/">
            <font-awesome-icon icon="chevron-left" size="lg" />
          </b-link>
        </h1>
      </b-row>
      <span class="container-centered">
        <p v-if="restaurant === null">
          {{ $t('Nonexistent Error') }}
        </p>
        <LoadingIcon
          v-else
          class="container-centered"
          class-name="loading-icon-big"
        />
      </span>
    </div>
  </div>
</template>

<script>
import { Carousel3d, Slide } from 'vue-carousel-3d';
import { mapActions } from 'vuex';
import gql from 'graphql-tag';
import LoadingIcon from '@/components/LoadingIcon.vue';
import Rating from '@/components/Rating.vue';
import LunchReports from '@/components/LunchReports';
import previewUnavailable from '../assets/no-image-available.png';
import 'vue-select/dist/vue-select.css';
import closedSign from '../assets/nostring-store-closed.svg';

export default {
  name: 'RestaurantDetailComponent',
  components: {
    LoadingIcon,
    Rating,
    Carousel3d,
    Slide,
    LunchReports,
  },
  props: {
    restaurant: {
      type: Object,
      default: undefined,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      slide: 0,
      sliding: null,
      previewUnavailable,
      closedSign,
      autoplayTimeout: 3000,
      controlsVisible: window.innerWidth <= 450,
      google_maps_api_key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
      isEditingTags: false,
      tagIDsToBeRemoved: [],
      tagsToAdd: [],
      allTags: [],
      selectedTag: null,
    };
  },
  apollo: {
    allTags: gql`
      {
        allTags: tags {
          id
          name
        }
      }
    `,
    me: gql`
      {
        me: me {
          inGIPLunch
        }
      }
    `,
  },
  computed: {
    tempEditedTags() {
      return this.restaurant.tags
        .filter(tag => !this.tagIDsToBeRemoved.includes(tag.id))
        .concat(this.tagsToAdd)
        .sort(this.compare);
    },
    addableTags() {
      return this.allTags
        ? this.allTags
            .filter(
              tag =>
                tag.name !== 'permanently closed' &&
                !this.tempEditedTags.map(t => t.id).includes(tag.id)
            )
            .sort(this.compare)
        : [];
    },
    tags() {
      return this.restaurant.tags.concat().sort(this.compare);
    },
    permanentlyClosed() {
      return this.tags.some(t => t.name === 'permanently closed');
    },
  },
  created() {
    window.addEventListener('resize', this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    ...mapActions(['updateTagFilters', 'setRestaurantOfNewLunch']),
    onResize() {
      this.controlsVisible = window.innerWidth <= 450;
    },
    addTag() {
      if (this.selectedTag) {
        if (this.tagIDsToBeRemoved.includes(this.selectedTag.id)) {
          this.tagIDsToBeRemoved = this.tagIDsToBeRemoved.filter(
            tagId => tagId !== this.selectedTag.id
          );
        } else {
          this.tagsToAdd.push(this.selectedTag);
          this.selectedTag = null;
        }
      }
    },
    unTag(tagIDToBeRemoved) {
      if (this.tagsToAdd.map(tag => tag.id).includes(tagIDToBeRemoved)) {
        this.tagsToAdd = this.tagsToAdd.filter(
          tag => tag.id !== tagIDToBeRemoved
        );
      } else if (this.tags.map(tag => tag.id).includes(tagIDToBeRemoved)) {
        this.tagIDsToBeRemoved.push(tagIDToBeRemoved);
      }
    },
    isEditingTagsOn() {
      this.isEditingTags = true;
    },
    isEditingTagsOff() {
      if (this.tagsToAdd.length && this.tagIDsToBeRemoved.length) {
        this.$emit('addAndRemoveTags', this.tagsToAdd, this.tagIDsToBeRemoved);
      } else if (this.tagsToAdd.length) {
        this.$emit('addTags', this.tagsToAdd);
      } else if (this.tagIDsToBeRemoved) {
        this.$emit('untag', this.tagIDsToBeRemoved);
      }
      this.tagsToAdd = [];
      this.tagIDsToBeRemoved = [];
      this.isEditingTags = false;
    },
    redirectHomeWithTagFilter(tag) {
      this.updateTagFilters([tag.id]);
      this.$router.push('/');
    },
    compare(tagA, tagB) {
      // Use toUpperCase() to ignore character casing
      const nameA = tagA.name.toUpperCase();
      const nameB = tagB.name.toUpperCase();

      let comparison = 0;
      if (nameA > nameB) {
        comparison = 1;
      } else if (nameA < nameB) {
        comparison = -1;
      }
      return comparison;
    },
    openSubmitReportBtnClicked() {
      this.setRestaurantOfNewLunch(this.restaurant);
      this.$bvModal.show('submit-report-modal');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../assets/sass/components/RestaurantDetailComponent.scss';
</style>

<style lang="scss">
@import '@/assets/sass/base/variables';

.RestaurantDetail {
  .v-select .vs__search::placeholder,
  .v-select .vs__dropdown-toggle,
  .v-select .vs__dropdown-menu,
  .v-select .vs__selected {
    background: white;
    border: none;
    color: $main-font-color;
  }
  .v-select .vs__search::placeholder {
    text-transform: none;
  }
  .v-select .vs__clear,
  .v-select .vs__open-indicator {
    fill: $main-font-color;
  }
}
</style>

<i18n>
{
  "en": {
    "Address": "Address",
    "Name": "Name",
    "Phone number": "Phone number",
    "Google Rating": "Google Rating",
    "Website": "Website",
    "GIP Lunch": "GIP lunch | GIP lunches",
    "No tags": "No tags added yet",
    "Add a new tag": "Add a new tag",
    "Nonexistent Error": "Oops, the restaurant does not exist.",
    "Save": "Save",
    "Lunches": "Lunches",
    "There have been no GIP lunches at this restaurant": "There have been no GIP lunches at this restaurant",
    "Report": "Report"
  },
  "ja": {
    "Address": "住所",
    "Name": "店名",
    "Phone number": "電話番号",
    "Google Rating": "グーグル 格付け",
    "Website": "ウェブサイト",
    "GIP Lunch": "GIP ランチ",
    "No tags": "まだタグがありません",
    "Add a new tag": "タグを追加する",
    "Nonexistent Error": "お探しのお店は見つかりませんでした",
    "Save": "保存",
    "Lunches": "ランチ",
    "There have been no GIP lunches at this restaurant": "このお店はGIPランチでは使用されたことがありません",
    "Report": "投稿"
  }
}
</i18n>
