var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.readyToDisplay)?_c('div',{ref:"canvas",staticClass:"container-centered"},[_c('blockquote',[_c('p',[_vm._v(" "+_vm._s(_vm.quotes[_vm.chosen_quote].quote)+"” "),_c('cite',[_vm._v(_vm._s(_vm.quotes[_vm.chosen_quote].cite))])])]),_c('b-img',{ref:"riceball",staticClass:"riceball-generate",attrs:{"src":_vm.Onigiri}}),_vm._l((_vm.grainArray[_vm.grainArray.length - 1]),function(index){return _c('div',{key:("grain_" + index),ref:"grains",refInFor:true,style:({
      left:
        _vm.canvasRadius +
        _vm.canvasRadius * 0.9 * Math.sin(_vm.portionArray[index - 1] * 2 * Math.PI) +
        'px',
      top:
        _vm.canvasRadius -
        _vm.canvasRadius * 0.9 * Math.cos(_vm.portionArray[index - 1] * 2 * Math.PI) +
        'px',
      transform: 'rotate(' + (_vm.portionArray[index - 1] * 360 + 90) + 'deg)',
    })},[_c('div',{staticClass:"rice"})])})],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }