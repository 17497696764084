<template>
  <div v-if="!readyToDisplay" ref="canvas" class="container-centered">
    <blockquote>
      <p>
        {{ quotes[chosen_quote].quote }}”
        <cite>{{ quotes[chosen_quote].cite }}</cite>
      </p>
    </blockquote>
    <b-img ref="riceball" :src="Onigiri" class="riceball-generate" />
    <div
      v-for="index in grainArray[grainArray.length - 1]"
      :key="`grain_${index}`"
      ref="grains"
      :style="{
        left:
          canvasRadius +
          canvasRadius * 0.9 * Math.sin(portionArray[index - 1] * 2 * Math.PI) +
          'px',
        top:
          canvasRadius -
          canvasRadius * 0.9 * Math.cos(portionArray[index - 1] * 2 * Math.PI) +
          'px',
        transform: 'rotate(' + (portionArray[index - 1] * 360 + 90) + 'deg)',
      }"
    >
      <div class="rice" />
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import Onigiri from '@/assets/filled-riceball.svg';
import RandomRestaurant from '@/graphql/RandomRestaurant.gql';
import Restaurant from '@/graphql/Restaurant.gql';

export default {
  name: 'RandomRestaurant',
  data() {
    return {
      Onigiri,
      splashing: null,
      grainArray: [12],
      canvasWidth: 0,
      portionArray: [...(null, Array(12))].map((x, i) => i / 12),
      curFrame: 0,
      interval: 10,
      pxPerFrame: 9,
      cycle: 0,
      addGrainPeriod: 16,
      transformRegex: /translate\((.*?)px, (.*?)px\)/,
      randomRestaurant: undefined,
      timesUp: false,
      quotes: [
        {
          quote: 'All happiness depends on a leisurely breakfast.',
          cite: 'John Gunther',
        },
        {
          quote: 'People who love to eat are always the best people.',
          cite: 'Julia Child',
        },
        {
          quote: 'First we eat, then we do everything else.',
          cite: 'M.F.K. Fisher',
        },
        {
          quote:
            'The only thing I like better than talking about Food is eating.',
          cite: 'John Walters',
        },
        {
          quote:
            'There are people in the world so hungry, that God cannot appear to them except in the form of bread.',
          cite: 'Mahatma Gandhi',
        },
        {
          quote:
            'If you gaze long enough into ramen, ramen will gaze back into you.',
          cite: 'Frodrick Kneecher',
        },
        {
          quote:
            'Ask not what you can do for your country. Ask what’s for lunch.',
          cite: 'Orson Welles',
        },
        {
          quote:
            'We choose to eat these steaks in this decade and do the other things, not because they are beefy, but because they are charred.',
          cite: 'Jean F. Celery',
        },
        {
          quote: 'Food is ten times better when shared.',
          cite: 'Michelle Lei',
        },
        {
          quote:
            'We should look for someone to eat and drink with before looking for something to eat and drink.',
          cite: 'Epicurus',
        },
      ],
    };
  },
  computed: {
    locale() {
      return this.$root.$i18n.locale;
    },
    canvasRadius() {
      return this.canvasWidth / 2;
    },
    readyToDisplay() {
      return this.timesUp && this.randomRestaurant !== undefined;
    },
    chosen_quote() {
      return Math.floor(Math.random() * (this.quotes.length - 0.1));
    },
  },
  watch: {
    locale() {
      if (this.randomRestaurant !== undefined) {
        this.$router.push(`/restaurant/${this.randomRestaurant.id}`);
      } else {
        this.$apollo.queries.randomRestaurant.refresh();
      }
    },
    readyToDisplay(newValue) {
      if (newValue === true) {
        this.$router.replace(`/restaurant/${this.randomRestaurant.id}`);
      }
    },
  },
  mounted() {
    const { canvas } = this.$refs;
    this.canvasWidth = canvas.offsetWidth;
    const { grains } = this.$refs;
    this.splashing = setInterval(() => {
      // pxPerFrame is linearly proportional to the Cubic Bezier of the spinning onigiri
      const t = (this.curFrame * this.interval) / 5000;
      const ratio = (1 - t) ** 4;
      const curPxPerFrame = this.pxPerFrame;
      grains.forEach((grain, index) => {
        const transform =
          grain.style.getPropertyValue('-webkit-transform') ||
          grain.style.getPropertyValue('-moz-transform') ||
          grain.style.getPropertyValue('-ms-transform') ||
          grain.style.getPropertyValue('-o-transform') ||
          grain.style.getPropertyValue('transform');
        const match = this.transformRegex.exec(transform);
        const curTranslateX = match ? parseFloat(match[1]) : 0;
        const curTranslateY = match ? parseFloat(match[2]) : 0;
        const rad = this.portionArray[index] * 2 * Math.PI;
        const translateX = curTranslateX + curPxPerFrame * Math.cos(rad);
        const translateY = curTranslateY + curPxPerFrame * Math.sin(rad);
        grain.style.transform = `translate(${translateX}px, ${translateY}px) rotate(${this
          .portionArray[index] *
          360 +
          90}deg)`;
      });
      if (this.cycle === this.addGrainPeriod) {
        const newGrain = Math.ceil(ratio * 15);
        this.grainArray.push(
          this.grainArray[this.grainArray.length - 1] + newGrain
        );
        for (let i = 0; i < newGrain; i++) {
          // Add a random factor
          this.portionArray.push(i / newGrain + Math.random() * 0.1);
        }
        const newAddGrainPeriod = Math.ceil(this.addGrainPeriod / (1 - t));
        this.addGrainPeriod =
          newAddGrainPeriod > 35 ? 1000000 : newAddGrainPeriod;
        this.cycle = 0;
      } else {
        this.cycle += 1;
      }
      this.curFrame += 1;
    }, this.interval);
    setTimeout(() => {
      clearInterval(this.splashing);
      this.splashing = null;
      this.timesUp = true;
    }, 5000);
  },
  apollo: {
    randomRestaurant: {
      query: RandomRestaurant,
      fetchPolicy: 'no-cache',
      result({ data, loading }) {
        if (!loading && data.randomRestaurant) {
          this.$apollo.provider.defaultClient.writeQuery({
            query: Restaurant,
            data: {
              restaurant: data.randomRestaurant,
            },
            variables: {
              id: data.randomRestaurant.id,
            },
          });
        }
      },
    },
  },
  created() {
    window.addEventListener('popstate', this.onPopState);
    window.addEventListener('beforeunload', this.onRefresh);
  },
  beforeDestroy() {
    clearInterval(this.splashing);
    window.removeEventListener('popstate', this.onPopState);
    window.removeEventListener('beforeunload', this.onRefresh);
  },
  methods: {
    onPopState() {
      window.history.back(1);
    },
    onRefresh() {
      if (this.readyToDisplay) {
        window.location.replace(`/restaurant/${this.randomRestaurant.id}`);
      }
    },
    addTags(tags) {
      if (!tags || tags.length === 0) {
        return;
      }
      tags.forEach(tag => {
        this.randomRestaurant.tags.push(tag);
        this.$apollo.mutate({
          mutation: gql`
            mutation($restaurant_id: ID!, $tag_id: ID!) {
              tagRestaurant(restaurant_id: $restaurant_id, tag_id: $tag_id) {
                added_tag {
                  id
                  name
                }
                updated_restaurant {
                  id
                  tags {
                    id
                    name
                  }
                }
              }
            }
          `,
          variables: {
            restaurant_id: this.randomRestaurant.id,
            tag_id: tag.id,
          },
        });
      });
    },
    untag(tagIDsToBeRemoved) {
      if (!tagIDsToBeRemoved || tagIDsToBeRemoved.length === 0) {
        return;
      }
      this.randomRestaurant.tags = this.randomRestaurant.tags.filter(
        curTag => !tagIDsToBeRemoved.includes(curTag.id)
      );
      tagIDsToBeRemoved.forEach(tag => {
        this.$apollo.mutate({
          mutation: gql`
            mutation($restaurant_id: ID!, $tag_id: ID!) {
              unTagRestaurant(restaurant_id: $restaurant_id, tag_id: $tag_id) {
                updated_restaurant {
                  id
                  tags {
                    id
                    name
                  }
                }
              }
            }
          `,
          variables: {
            restaurant_id: this.randomRestaurant.id,
            tag_id: tag,
          },
        });
      });
    },
  },
};
</script>
