<template>
  <RestaurantDetailComponent
    :restaurant="restaurant"
    :loading="loading_tag"
    @addTags="addTags"
    @untag="untag"
    @addAndRemoveTags="addAndRemoveTags"
  />
</template>

<script>
import gql from 'graphql-tag';
import RestaurantDetailComponent from '@/components/RestaurantDetailComponent.vue';
import Restaurant from '@/graphql/Restaurant.gql';
import RestaurantAndLunches from '@/graphql/RestaurantAndLunches.gql';
import BatchTagRestaurant from '@/graphql/BatchTagRestaurant.gql';
import BatchUnTagRestaurant from '@/graphql/BatchUnTagRestaurant.gql';

export default {
  name: 'RestaurantDetail',
  components: {
    RestaurantDetailComponent,
  },
  data() {
    return {
      adding_tags: false,
      removing_tags: false,
      restaurantAndLunchesSkip: true,
      restaurantWithoutLunchesSkip: true,
    };
  },
  computed: {
    loading_tag() {
      return this.adding_tags || this.removing_tags;
    },
    locale() {
      return this.$root.$i18n.locale;
    },
    restaurant() {
      return this.restaurantAndLunches || this.restaurantWithoutLunches;
    },
  },
  watch: {
    locale() {
      this.restaurant = undefined;
      window.scrollTo(0, 0);
      this.$apollo.queries.restaurant.refresh();
    },
  },
  apollo: {
    me: {
      query: gql`
        {
          me: me {
            inGIPLunch
          }
        }
      `,
      update(data) {
        if (data.me.inGIPLunch) {
          this.restaurantAndLunchesSkip = false;
        } else {
          this.restaurantWithoutLunchesSkip = false;
        }
      },
    },
    restaurantWithoutLunches: {
      query: Restaurant,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
      skip() {
        return this.restaurantWithoutLunchesSkip;
      },
      update(data) {
        return data.restaurant;
      },
    },
    restaurantAndLunches: {
      query: RestaurantAndLunches,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
      skip() {
        return this.restaurantAndLunchesSkip;
      },
      update(data) {
        return data.restaurant;
      },
    },
  },
  methods: {
    async addTags(tags) {
      if (!tags || tags.length === 0) {
        return;
      }
      this.adding_tags = true;
      await this.$apollo.mutate({
        mutation: BatchTagRestaurant,
        variables: {
          restaurant_id: this.restaurant.id,
          tag_ids: tags.map(tag => tag.id),
        },
        optimisticResponse: () => {
          const added_tags = tags.map(tag => ({
            __typename: 'Tag',
            id: tag.id,
            name: tag.name,
          }));
          const addedTags = tags.map(tag => ({
            __typename: 'Tag',
            id: tag.id,
          }));
          const currentTags = this.restaurant.tags.map(tag => ({
            __typename: 'Tag',
            id: tag.id,
          }));
          //construct an array of existing tag ids and new tag ids
          const newTags = addedTags.concat(currentTags);
          const updated_restaurant = {
            __typename: 'Restaurant',
            id: this.restaurant.id,
            tags: newTags,
          };
          this.adding_tags = false;
          return {
            batchTagRestaurant: {
              __typename: 'BatchTagRestaurantPayloadType',
              added_tags,
              updated_restaurant,
            },
          };
        },
      });
    },
    untag(tagIDsToBeRemoved) {
      if (!tagIDsToBeRemoved || tagIDsToBeRemoved.length === 0) {
        return;
      }
      this.removing_tags = true;
      this.$apollo.mutate({
        mutation: BatchUnTagRestaurant,
        variables: {
          restaurant_id: this.restaurant.id,
          tag_ids: tagIDsToBeRemoved,
        },
        optimisticResponse: () => {
          const newTags = this.restaurant.tags
            .filter(tag => !tagIDsToBeRemoved.includes(tag.id))
            .map(tag => ({
              __typename: 'Tag',
              id: tag.id,
            }));
          //construct an array of existing tag ids and new tag ids
          const updated_restaurant = {
            __typename: 'Restaurant',
            id: this.restaurant.id,
            tags: newTags,
          };
          this.removing_tags = false;
          return {
            batchUnTagRestaurant: {
              __typename: 'BatchUnTagRestaurantPayloadType',
              updated_restaurant,
            },
          };
        },
      });
    },
    async addAndRemoveTags(tagsToAdd, tagIDsToBeRemoved) {
      this.removing_tags = true;
      await this.addTags(tagsToAdd);
      this.untag(tagIDsToBeRemoved);
    },
  },
};
</script>
