import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import './assets/sass/index.scss';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faHeart,
  faUtensils,
  faChevronLeft,
  faGlobeAsia,
  faPhoneAlt,
  faMapMarkerAlt,
  faGlobe,
  faFilter,
  faTags,
  faCog,
  faCheckCircle,
  faPlus,
  faBars,
  faHome,
  faQuestionCircle,
  faPaperPlane,
  faPencilAlt,
} from '@fortawesome/free-solid-svg-icons';
import { LoaderPlugin } from 'vue-google-login';
import vSelect from 'vue-select';
import * as VueGoogleMaps from 'vue2-google-maps';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
import { createProvider } from './vue-apollo';
import VueAnalytics from 'vue-analytics';
import vuetify from './plugins/vuetify';

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
    libraries: 'places',
    installComponents: true,
  },
});

library.add(faHeart);
library.add(faUtensils);
library.add(faChevronLeft);
library.add(faGlobeAsia);
library.add(faPhoneAlt);
library.add(faMapMarkerAlt);
library.add(faGlobe);
library.add(faFilter);
library.add(faTags);
library.add(faCog);
library.add(faCheckCircle);
library.add(faPlus);
library.add(faBars);
library.add(faHome);
library.add(faQuestionCircle);
library.add(faPaperPlane);
library.add(faPencilAlt);

Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.use(BootstrapVue);
Vue.use(LoaderPlugin, {
  client_id:
    '267943453777-99qfoi92aauf3iidfiq0ir209juumdoc.apps.googleusercontent.com',
});
Vue.component('vue-select', vSelect);

Vue.config.productionTip = false;

if (process.env.VUE_APP_GOOGLE_ANALYTICS_ON === '1') {
  Vue.use(VueAnalytics, {
    id: 'UA-146958525-1',
    router,
  });
}

new Vue({
  router,
  store,
  i18n,
  apolloProvider: createProvider(),
  vuetify,
  render: h => h(App),
}).$mount('#app');
