import { render, staticRenderFns } from "./SubmitReportModal.vue?vue&type=template&id=5bcaa113&scoped=true&"
import script from "./SubmitReportModal.vue?vue&type=script&lang=js&"
export * from "./SubmitReportModal.vue?vue&type=script&lang=js&"
import style0 from "./SubmitReportModal.vue?vue&type=style&index=0&id=5bcaa113&lang=scss&scoped=true&"
import style1 from "./SubmitReportModal.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bcaa113",
  null
  
)

/* custom blocks */
import block0 from "./SubmitReportModal.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
installComponents(component, {VAutocomplete})
