<template>
  <div id="app" :class="{ noScrolling: modalVisible }">
    <div class="overlay" :style="{ display: displayOverlay }" />
    <MobileMenuButton
      v-if="isAuthenticated && isMobile"
      :nav-drawer-shown="navDrawerToggle"
      @click="navDrawerToggle = !navDrawerToggle"
    />
    <NavDrawer
      v-if="isAuthenticated && isMobile"
      :drawer="navDrawerToggle"
      @close="navDrawerToggle = false"
    />
    <Navbar v-if="isAuthenticated && !isMobile" />
    <b-container :class="{ 'grid-container': isAuthenticated }">
      <router-view />
    </b-container>
  </div>
</template>

<style lang="scss"></style>

<script>
import { mapActions, mapState } from 'vuex';
import Vue from 'vue';
import Logo from '@/assets/filled-riceball.svg';
import Navbar from '@/components/Navbar.vue';
import MobileMenuButton from '@/components/MobileMenuButton.vue';
import NavDrawer from '@/components/NavDrawer.vue';
import router from './router';
import { onLogin, onLogout } from './vue-apollo';

export default {
  name: 'App',
  components: {
    Navbar,
    NavDrawer,
    MobileMenuButton,
  },
  data() {
    return {
      Logo,
      navDrawerToggle: false,
    };
  },
  computed: {
    ...mapState(['authStatus', 'seenNewFeat', 'modalVisible']),
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    displayOverlay() {
      return this.$route.name === 'Login' || this.seenNewFeat
        ? 'none'
        : 'initial';
    },
    isMobile() {
      const windowWidth = document.documentElement.clientWidth;
      if (windowWidth <= 767) {
        return true;
      }
      if (windowWidth > 1366) {
        return false;
      }
      const ua = navigator.userAgent;
      const isTabletUA = /(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(
        ua
      );
      const isMobileUA = /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      );
      if (isTabletUA || isMobileUA) {
        return true;
      }
      return false;
    },
  },
  created() {
    if (!this.seenNewFeat) {
      window.addEventListener(
        'mousedown',
        this.enableUserInteractionsAfterLogin
      );
      document.body.style.overflow = 'hidden';
    }
    Vue.GoogleAuth.then(auth2 => {
      auth2.isSignedIn.listen(this.signinChanged);
      auth2.currentUser.listen(this.currentUserChanged);
      if (auth2.isSignedIn.get()) {
        const responseData = auth2.currentUser.get().getAuthResponse();
        if (responseData) {
          this.changeSigninState(true);
          this.updateIdToken(responseData.id_token);
          onLogin(this.$apollo.provider.defaultClient, responseData.id_token);
        }
      }
    });
  },
  beforeDestroy() {
    window.removeEventListener(
      'mousedown',
      this.enableUserInteractionsAfterLogin
    );
  },
  methods: {
    ...mapActions([
      'changeSigninState',
      'updateIdToken',
      'unsetIdToken',
      'updateSeenNewFeat',
    ]),
    signinChanged(newSigninState) {
      this.changeSigninState(newSigninState);
      if (newSigninState) {
        Vue.GoogleAuth.then(auth2 => {
          const currentUser = auth2.currentUser.get();
          this.currentUserChanged(currentUser).then(() => {
            if (this.$route.path === '/login') {
              router.push('/');
            }
          });
        });
      } else {
        this.unsetIdToken();
        onLogout(this.$apollo.provider.defaultClient);
        if (this.$route.path !== '/login') {
          router.push('/login');
        }
      }
    },
    async currentUserChanged(newCurrentUser) {
      const responseData = newCurrentUser.getAuthResponse();
      if (responseData) {
        this.updateIdToken(responseData.id_token);
        await onLogin(
          this.$apollo.provider.defaultClient,
          responseData.id_token
        );
      } else {
        this.unsetIdToken();
        onLogout(this.$apollo.provider.defaultClient);
      }
    },
    enableUserInteractionsAfterLogin() {
      if (this.$route.name !== 'Login') {
        document.body.style.overflow = 'auto';
        this.updateSeenNewFeat(true);
        window.removeEventListener(
          'mousedown',
          this.enableUserInteractionsAfterLogin
        );
      }
    },
  },
};
</script>
