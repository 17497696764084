export default {
  updateSelectedCard: ({ commit }, payload) => {
    commit('selectCard', payload);
  },
  changeSigninState: ({ commit }, payload) => {
    commit('updateSigninState', payload);
  },
  updateIdToken: ({ commit }, idToken) => {
    commit('setIdToken', idToken);
  },
  unsetIdToken: ({ commit }) => {
    commit('removeIdToken');
  },
  updateTagFilters: ({ commit }, payload) => {
    commit('updateTagFilters', payload);
  },
  updateSeenNewFeat: ({ commit }, payload) => {
    commit('updateSeenNewFeat', payload);
  },
  updateModalVisible: ({ commit }, payload) => {
    commit('updateModalVisible', payload);
  },
  setRestaurantOfNewLunch: ({ commit }, payload) => {
    commit('setIsNewLunchVirtual', false);
    commit('setRestaurantOfNewLunch', payload);
  },
  setIsNewLunchVirtual: ({ commit }) => {
    commit('setIsNewLunchVirtual', true);
    commit('setRestaurantOfNewLunch', null);
  },
};
