<template>
  <div class="report__card">
    <div class="report__header">
      <b-img
        :src="lunch.msg_usr.slack_user.avatar || blankAvatar"
        alt="profile`"
        rounded="circle"
        thumbnail
      />
      <dl class="report__header-text">
        <dt class="report__author">
          {{
            lunch.msg_usr.slack_user.real_name || lunch.msg_usr.slack_user.name
          }}
          <span class="report__count"
            >{{ lunch.msg_usr.lunch_reports_written.total }} reports</span
          >
        </dt>
        <dd class="report__time">
          <template v-if="lunch.ratings">
            <span
              v-for="star in lunch.ratings"
              :key="`lunch-${lunch.id}-star-${star}`"
              >🌟
            </span>
          </template>
          {{ parsedLunchDate }}
        </dd>
        <dd v-if="displayRestaurantName && lunch.restaurant">
          <b-link
            v-if="lunch.virtual !== true"
            :to="`/restaurant/${lunch.restaurant.id}`"
          >
            {{ lunch.restaurant.name }}
          </b-link>
          <span v-else>
            {{ $t('Online') }}
          </span>
        </dd>
        <dd>
          {{ $t('Hunters') }}:
          <span
            v-for="(member, idx) in lunch.members"
            :key="`${idx}-slack-id-${member.slack_user.id}`"
            class="report__hunter"
            >{{ member.slack_user.real_name || member.slack_user.name }}</span
          >
        </dd>
      </dl>
    </div>
    <p
      :ref="`lunch_body_${lunch.id}`"
      :class="['report__body', { 'report__body--collapsed': isBodyOverflow }]"
    >
      {{ lunch.body }}
    </p>
    <ShowMoreLessButton
      v-if="bodyOverflowInitial"
      :is-body-overflow="isBodyOverflow"
      class="report__button"
      @click="isBodyOverflow = !isBodyOverflow"
    />
  </div>
</template>

<script>
import Me from '@/graphql/Me.gql';
import blankAvatar from '@/assets/blank_avatar.jpg';
import ShowMoreLessButton from '@/components/ShowMoreLessButton.vue';

export default {
  name: 'LunchReport',
  components: {
    ShowMoreLessButton,
  },
  props: {
    lunch: {
      type: Object,
      default: () => ({}),
    },
    displayRestaurantName: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    const oneDay = 60 * 60 * 24;
    return {
      blankAvatar,
      bodyOverflowInitial: true,
      isBodyOverflow: true,
      oneDay,
      oneWeek: oneDay * 7,
      oneMonth: oneDay * 30,
    };
  },
  computed: {
    parsedLunchDate() {
      if (!this.lunch.lunch_date) {
        return this.$t('unknown date');
      }
      const now = new Date();
      const lunch_date = new Date(this.lunch.lunch_date);
      const diff = (now - lunch_date) / 1000;
      if (diff < this.oneDay) {
        return this.$t('today');
      } else if (diff < this.oneWeek) {
        const count = Math.ceil(diff / this.oneDay);
        return this.$tc('day ago', count, { count });
      } else if (diff < this.oneMonth) {
        const count = Math.ceil(diff / this.oneWeek);
        return this.$tc('week ago', count, { count });
      } else if (diff <= 11 * this.oneMonth) {
        const count = Math.ceil(diff / this.oneMonth);
        return this.$tc('month ago', count, { count });
      } else {
        const count = now.getUTCFullYear() - lunch_date.getUTCFullYear();
        return this.$tc('year ago', count, { count });
      }
    },
  },
  mounted() {
    if (this.$refs[`lunch_body_${this.lunch.id}`]) {
      this.isBodyOverflow =
        this.$refs[`lunch_body_${this.lunch.id}`].offsetWidth <
          this.$refs[`lunch_body_${this.lunch.id}`].scrollWidth ||
        this.$refs[`lunch_body_${this.lunch.id}`].offsetHeight <
          this.$refs[`lunch_body_${this.lunch.id}`].scrollHeight;
      this.bodyOverflowInitial = this.isBodyOverflow;
    }
  },
};
</script>

<style lang="scss" scoped>
@import '../assets/sass/components/LunchReport.scss';
</style>

<i18n>
{
  "en": {
    "Hunters": "Hunters",
    "today": "today",
    "day ago": "0 day ago | one day ago | {count} days ago",
    "week ago": "0 week ago | one week ago | {count} weeks ago",
    "month ago": "0 month ago | one month ago | {count} months ago",
    "year ago": "0 year ago | one year ago | {count} years ago",
    "unknown date": "unknown date",
    "Online": "Held online"
  },
  "ja": {
    "Hunters": "ハンター",
    "today": "今日",
    "day ago": "0 日前 | 1 日前 | {count} 日前",
    "week ago": "0 週間前 | 1 週間前 | {count} 週間前",
    "month ago": "0 ヶ月前 | 1 ヶ月前 | {count} ヶ月前",
    "year ago": "0 年前 | 1 年前 | {count} 年前",
    "unknown date": "日付不明",
    "Online": "オンラインで"
  }
}
</i18n>
