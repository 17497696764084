import Vue from 'vue';
import Router from 'vue-router';
import Login from './views/Login.vue';
import Home from './views/Home.vue';
import RestaurantDetail from './views/RestaurantDetail.vue';
import RandomRestaurant from './views/RandomRestaurant.vue';
import About from './views/About.vue';
import MyLunches from './views/MyLunches.vue';

const ifNotAuthenticated = async (to, from, next) => {
  let authenticated = false;
  await Vue.GoogleAuth.then(auth2 => {
    authenticated = auth2.isSignedIn.get();
  });
  if (!authenticated) {
    next();
    return;
  }
  next('/');
};

const ifAuthenticated = async (to, from, next) => {
  let authenticated = false;
  await Vue.GoogleAuth.then(auth2 => {
    authenticated = auth2.isSignedIn.get();
  });
  if (authenticated) {
    next();
    return;
  }
  next('/login');
};

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
      beforeEnter: ifAuthenticated,
    },
    // {
    //   path: '/report',
    //   name: 'Report',
    //   component: Report,
    //   beforeEnter: ifAuthenticated,
    // },
    {
      path: '/about',
      name: 'About',
      component: About,
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
      beforeEnter: ifNotAuthenticated,
    },
    {
      path: '/restaurant/random',
      name: 'RandomRestaurant',
      component: RandomRestaurant,
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/restaurant/:id',
      name: 'RestaurantDetail',
      component: RestaurantDetail,
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/profile/my-lunches',
      name: 'MyLunches',
      component: MyLunches,
      beforeEnter: ifAuthenticated,
    },
    {
      path: '*',
      redirect: '/',
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});
