<template>
  <b-col class="col-md-4">
    <b-card
      :id="popover_id"
      no-body
      :title="restaurant.name"
      @click="$router.push(`/restaurant/${id}`)"
    >
      <b-card-img :src="previewImage" top :height="imageHeightString" />
      <div class="card-text-custom">
        <div>
          {{ restaurant.name }}
        </div>
        <b-button-toolbar justify aria-label="Toolbar with button groups">
          <b-button-group size="sm">
            <b-button
              v-if="restaurant.lunch_count > 0"
              class="btn-custom"
              disabled
            >
              <font-awesome-icon icon="utensils" size="lg" />
              <span style="padding-left: 0.3rem; font-size: 1rem;">{{
                restaurant.lunch_count
              }}</span>
            </b-button>
          </b-button-group>
        </b-button-toolbar>
      </div>
    </b-card>
  </b-col>
</template>

<script>
import { mapState } from 'vuex';
import previewUnavailable from '../assets/no-image-available.png';

export default {
  props: {
    id: {
      type: String,
      default: '0',
    },
    name: {
      type: String,
      default: 'restaurant name',
    },
    restaurant: {
      type: Object,
      default() {
        return {
          id: {
            type: String,
            default: '0',
          },
          name: {
            type: String,
            default: 'Gonpachi',
          },
          photos: {
            type: [String],
            default: [
              'https://gonpachi.jp/wp-content/uploads/sites/14/2018/12/gonpachi-soba-kushi02.jpg',
            ],
          },
          lunch_count: {
            type: String,
            default: '0',
          },
        };
      },
    },
    imageHeight: {
      type: Number,
      default: 100,
    },
  },
  data() {
    return {
      previewUnavailable,
    };
  },
  computed: {
    popover_id() {
      return `popover-${this.id.toString()}`;
    },
    ...mapState(['selected']),
    imageHeightString() {
      return `${this.imageHeight}`;
    },
    previewImage() {
      if (this.restaurant && this.restaurant.photos.length > 0) {
        return this.restaurant.photos[0];
      }

      return previewUnavailable;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../assets/sass/components/RestaurantPreview.scss';
</style>
