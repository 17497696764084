<template>
  <v-list>
    <div class="more-action-label">
      {{ $t('More Actions') }}
    </div>
    <div class="action-bar">
      <v-btn
        icon
        class="action-btn"
        @click="$bvModal.show('create-restaurant-modal')"
      >
        <div class="create-restaurant-icon">
          <CreateRestaurantModal @created="onRestaurantCreated" />
        </div>
      </v-btn>
      <v-btn icon class="action-btn" @click="openSubmitReportModal">
        <font-awesome-icon icon="pencil-alt" size="lg" />
        <SubmitReportModal />
      </v-btn>
    </div>
  </v-list>
</template>

<script>
import { mapActions } from 'vuex';
import CreateRestaurantModal from '@/components/CreateRestaurantModal.vue';
import SubmitReportModal from '@/components/SubmitReportModal.vue';

export default {
  name: 'MoreActions',
  components: {
    CreateRestaurantModal,
    SubmitReportModal,
  },
  methods: {
    ...mapActions(['setIsNewLunchVirtual']),
    onRestaurantCreated() {
      this.$root.$bvModal.msgBoxOk(
        `${this.$t(
          'Thank you! You have successfully added a restaurant to Gohunter.'
        )}
        🍙.`,
        {
          id: 'success-msg-modal',
          size: 'lg',
          headerClass: 'p-2 border-bottom-0',
          bodyClass: 'success-msg',
          modalClass: 'no-footer-modal',
          centered: true,
        }
      );
      setTimeout(() => {
        this.$root.$bvModal.hide('success-msg-modal');
      }, 3000);
    },
    openSubmitReportModal() {
      this.setIsNewLunchVirtual(true);
      this.$bvModal.show('submit-report-modal');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../assets/sass/components/MoreActions.scss';
</style>

<style lang="scss">
.action-bar {
  .action-btn.v-btn.v-btn--icon {
    color: rgba(0, 0, 0, 0.87);
  }
}
</style>

<i18n>
{
  "en": {
    "Thank you! You have successfully added a restaurant to Gohunter.": "Thank you! You have successfully added a restaurant to Gohunter.",
    "More Actions": "More Actions"
  },
  "ja": {
    "Thank you! You have successfully added a restaurant to Gohunter.": "レストラン登録は成功しました。ありがとうございます！",
    "More Actions": "その他操作"
  }
}
</i18n>
