<template>
  <span class="ratings">
    <b-img
      v-for="digit in Math.floor(rating)"
      :key="`filled-${digit}`"
      :src="filledRiceball"
      class="rating"
    />
    <b-img v-if="rating % 1 >= 0.5" :src="halfFilledRiceball" class="rating" />
    <template v-if="Math.ceil(rating) > Math.floor(rating)">
      <b-img
        v-for="digit in 4 - Math.floor(rating)"
        :key="`unfilled-${digit}`"
        :src="unfilledRiceball"
        class="rating"
      />
    </template>
    <b-img v-if="rating % 1 < 0.5" :src="unfilledRiceball" class="rating" />
  </span>
</template>

<script>
import filledRiceball from '../assets/filled-riceball.svg';
import halfFilledRiceball from '../assets/half-filled-riceball.svg';
import unfilledRiceball from '../assets/unfilled-riceball.svg';

export default {
  props: {
    rating: {
      type: Number,
      default: 4,
    },
  },
  data() {
    return {
      filledRiceball,
      halfFilledRiceball,
      unfilledRiceball,
    };
  },
  computed: {
    digits() {
      if (this.rating) {
        return Math.floor(this.rating);
      }
      return 0;
    },
    trailing() {
      if (this.rating % 1 >= 0.5) {
        return true;
      }

      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../assets/sass/components/Rating.scss';
</style>
