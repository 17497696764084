<template>
  <div>
    <b-button
      v-if="isBodyOverflow"
      variant="success"
      size="sm"
      @click="$emit('click')"
    >
      {{ $t('Show More') }}
    </b-button>
    <b-button v-else variant="success" size="sm" @click="$emit('click')">
      {{ $t('Show Less') }}
    </b-button>
  </div>
</template>

<script>
export default {
  name: 'ShowMoreLessButton',
  props: {
    isBodyOverflow: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<i18n>
{
  "en": {
    "Show More": "Show More",
    "Show Less": "Show Less"
  },
  "ja": {
    "Show More": "詳細表示",
    "Show Less": "詳細閉じる"
  }
}
</i18n>
