export default {
  selectCard: (state, cardIndex) => {
    state.selected = cardIndex;
  },
  updateSigninState: (state, signinState) => {
    state.authStatus = signinState;
  },
  setIdToken: (state, idToken) => {
    state.idToken = idToken;
  },
  removeIdToken: state => {
    state.idToken = '';
  },
  updateTagFilters: (state, filters) => {
    state.tagFilters = filters;
  },
  updateSeenNewFeat: (state, flag) => {
    state.seenNewFeat = flag;
    localStorage.setItem('seenMyLunchesFeat', flag);
  },
  updateModalVisible: (state, modalVisible) => {
    state.modalVisible = modalVisible;
  },
  setRestaurantOfNewLunch: (state, restaurantOfNewLunch) => {
    state.restaurantOfNewLunch = restaurantOfNewLunch;
  },
  setIsNewLunchVirtual: (state, isNewLunchVirtual) => {
    state.isNewLunchVirtual = isNewLunchVirtual;
  },
};
