import mises from '../data/mises';

export default {
  mises,
  selected: -1,
  idToken: localStorage.getItem('apollo-token') || '',
  authStatus: false,
  tagFilters: [],
  seenNewFeat: true, // TODO: do not have time to set this up for the submit lunch report feature
  modalVisible: false,
  restaurantOfNewLunch: null,
  isNewLunchVirtual: false,
};
