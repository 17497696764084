<template>
  <b-modal
    id="submit-report-modal"
    size="xl"
    :cancel-title="$t('Reset')"
    :ok-title="$t('Submit')"
    no-close-on-backdrop
    data-app
    @hide="onHide"
    @shown="
      () => {
        updateModalVisible(true);
      }
    "
  >
    <div class="overlay" :style="{ display: displayOverlay }" />
    <template #modal-title>
      {{ $t('Submit a GIP Lunch Report') }}
    </template>
    <b-form @submit="reportLunchMutation" @reset="onReset">
      <div class="date-and-place-container">
        <b-form-group
          label-size="md"
          :label="$t('When?')"
          label-for="input-lg"
          class="report__label"
        >
          <b-form-datepicker
            v-model="date"
            :placeholder="$t('When was the lunch?')"
            :locale="$root.$i18n.locale"
            required
            :max="today"
          />
        </b-form-group>
        <b-form-group
          label-size="md"
          :label="$t('Where?')"
          label-for="input-lg"
          class="report__label"
        >
          <p class="lunch-restaurant">
            {{
              restaurantOfNewLunch && !isNewLunchVirtual
                ? restaurantOfNewLunch.name
                : $t('In the cloud (virtual)')
            }}
          </p>
        </b-form-group>
      </div>
      <div class="details-container">
        <v-autocomplete
          v-model="members"
          :items="employeeItems"
          :label="$t('Enter or select all other participants...')"
          :reset-on-options-change="true"
          :select-on-tab="true"
          clearable
          multiple
          chips
          deletable-chips
        >
        </v-autocomplete>
        <b-form-textarea
          v-model="reportBody"
          :state="null"
          :placeholder="
            $t('Tell us about your dining experience with the intern.')
          "
          class="report__body"
        ></b-form-textarea>
      </div>
    </b-form>
    <template #modal-footer>
      <b-button
        variant="outline-secondary"
        :disabled="submitReportMutationLoading"
        @click="onReset"
      >
        {{ $t('Reset') }}
      </b-button>
      <b-button
        variant="outline-success"
        class="ok-btn"
        :disabled="invalidFormInput || submitReportMutationLoading"
        @mouseover="okHovered = true"
        @mouseleave="okHovered = false"
        @click="reportLunchMutation"
      >
        <font-awesome-icon
          far
          icon="paper-plane"
          size="lg"
          :class="{ 'paper-plane__hovered': okHovered }"
        />
        {{ $t('Submit') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { gmapApi } from 'vue2-google-maps';
import gql from 'graphql-tag';
import 'vue-select/dist/vue-select.css';
import Employees from '@/graphql/Employees.gql';
import Me from '@/graphql/Me.gql';

export default {
  name: 'SubmitReportModal',
  data() {
    return {
      displayOverlay: 'none',
      submitReportMutationLoading: false,
      today: new Date(),
      date: '',
      reportBody: '',
      isVirtualLunch: false,
      members: null,
      okHovered: false,
      employeesCursor: '',
      hasMoreEmployees: true,
    };
  },
  apollo: {
    employees: {
      query: Employees,
      variables: {
        after: '',
      },
      skip() {
        return !this.hasMoreEmployees;
      },
      update(data) {
        if (
          !!data.employees.cursor &&
          data.employees.cursor !== this.employeesCursor
        ) {
          this.employeesCursor = data.employees.cursor;
          this.$apollo.queries.employees.fetchMore({
            // New variables
            variables: {
              after: data.employees.cursor,
            },
            // Transform the previous result with new data
            updateQuery: (previousResult, { fetchMoreResult }) => {
              if (
                !fetchMoreResult.employees.cursor ||
                fetchMoreResult.employees.cursor ===
                  previousResult.employees.cursor
              ) {
                this.hasMoreEmployees = false;
              }
              this.hasMoreEmployees = true;
              const newItems = fetchMoreResult.employees.items;
              return {
                employees: {
                  items: [...previousResult.employees.items, ...newItems],
                  cursor: fetchMoreResult.employees.cursor,
                  __typename: 'EmployeeConnection',
                },
              };
            },
          });
        }
        return data;
      },
    },
    me: {
      query: Me,
    },
  },
  computed: {
    ...mapState(['restaurantOfNewLunch', 'isNewLunchVirtual']),
    google: gmapApi,
    employeeItems() {
      return this.employees?.employees
        ? this.employees.employees.items
            .filter(
              e =>
                e.slack_user.id !==
                  (this.me && this.me.employee.slack_user.id) &&
                !e.slack_user.deleted
            )
            .map(e => ({
              text: e.slack_user.real_name || e.slack_user.name,
              value: e.slack_user.id,
            }))
        : [];
    },
    invalidFormInput() {
      return (
        this.reportBody === '' ||
        !this.members ||
        this.members.length === 0 ||
        this.date === ''
      );
    },
  },
  methods: {
    ...mapActions(['updateModalVisible']),
    reportLunchMutation() {
      this.disableUserInteractions();
      this.$apollo
        .mutate({
          mutation: gql`
            mutation(
              $lunch_date: String!
              $restaurant_id: ID
              $body: String!
              $restaurant_name: String
              $members: [ID!]!
              $virtual: Boolean!
            ) {
              createLunch(
                input: {
                  lunch_date: $lunch_date
                  body: $body
                  restaurant_id: $restaurant_id
                  restaurant_name: $restaurant_name
                  members: $members
                  virtual: $virtual
                }
              ) {
                new_lunch {
                  id
                }
              }
            }
          `,
          variables: {
            lunch_date: this.date,
            body: this.reportBody,
            restaurant_id:
              this.restaurantOfNewLunch && this.restaurantOfNewLunch.id,
            restaurant_name:
              this.restaurantOfNewLunch && this.restaurantOfNewLunch.name,
            members: this.members,
            virtual: this.isNewLunchVirtual,
          },
        })
        .then(({ data }) => {
          this.showSuccessMessage();
        })
        .catch(error => {
          this.showErrorMessage();
        })
        .finally(() => {
          this.enableUserInteractions();
          this.$bvModal.hide('submit-report-modal');
        });
    },
    disableUserInteractions() {
      this.okHovered = false;
      this.submitReportMutationLoading = true;
      this.displayOverlay = 'initial';
    },
    enableUserInteractions() {
      this.submitReportMutationLoading = false;
      this.displayOverlay = 'none';
    },
    showSuccessMessage() {
      this.$root.$bvModal.msgBoxOk(
        `${this.$t(
          'Thank you! You have successfully submitted a lunch report via Gohunter'
        )}
         🍙`,
        {
          id: 'success-msg-modal',
          size: 'lg',
          headerClass: 'p-2 border-bottom-0',
          bodyClass: 'success-msg',
          modalClass: 'no-footer-modal',
          centered: true,
        }
      );
      setTimeout(() => {
        this.$root.$bvModal.hide('success-msg-modal');
      }, 3000);
    },
    showErrorMessage() {
      this.$root.$bvModal.msgBoxOk(
        `${this.$t('There was an error. Please try again later.')}
         🍙.`,
        {
          id: 'error-msg-modal',
          size: 'lg',
          headerClass: 'p-2 border-bottom-0',
          bodyClass: 'error-msg',
          modalClass: 'no-footer-modal',
          centered: true,
        }
      );
      setTimeout(() => {
        this.$root.$bvModal.hide('error-msg-modal');
      }, 3000);
    },
    onReset() {
      this.date = '';
      this.members = null;
      this.reportBody = '';
    },
    onHide() {
      this.updateModalVisible(false);
      this.onReset();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../assets/sass/components/SubmitReportModal.scss';
</style>

<style lang="scss">
@import '@/assets/sass/base/_variables.scss';

#submit-report-modal {
  .theme--light.v-chip {
    background: darken($primary-color, 20%);
  }
}

.no-footer-modal {
  .modal-footer {
    display: none;
  }
}

.success-msg,
.error-msg {
  align-self: center;
}

.v-list-item__title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>

<i18n>
{
  "en": {
    "Submit a GIP Lunch Report": "Submit a GIP Lunch Report",
    "When?": "When?",
    "When was the lunch?": "When was the lunch?",
    "Where?": "Where?",
    "In the cloud (virtual)": "In the cloud (virtual)",
    "Enter or select all other participants...": "Enter or select all other participants...",
    "Tell us about your dining experience with the intern.": "Tell us about your dining experience with the intern.",
    "Reset": "Reset",
    "Submit": "Submit",
    "Thank you! You have successfully submitted a lunch report via Gohunter": "Thank you! You have successfully submitted a lunch report via Gohunter.",
    "There was an error. Please try again later.": "There was an error. Please try again later."
  },
  "ja": {
    "Submit a GIP Lunch Report": "GIPランチレポートの投稿",
    "When?": "いつ？",
    "When was the lunch?": "ランチはいつでしたか？",
    "Where?": "店は?",
    "In the cloud (virtual)": "オンライン",
    "Enter or select all other participants...": "他のすべてのメンバーを入力または選択してください...",
    "Tell us about your dining experience with the intern.": "インターンとのランチの印象を教えてください。",
    "Reset": "リセット",
    "Submit": "投稿",
    "Thank you! You have successfully submitted a lunch report via Gohunter": "ご投稿ありがとうございました。",
    "There was an error. Please try again later.": "エラーが発生しました。しばらくしてからもう一度お試しください。"
  }
}
</i18n>
